import { useCallback, useRef, useState } from 'react';

export function useDebounced<T>(
  func: (param: T) => Promise<void>,
  timeoutMs: number,
): [(param: T) => void, { loading: boolean }] {
  const timeout = useRef<number | null>(null);
  const [loading, setLoading] = useState(false);
  const cb = useCallback((val: T) => {
    if (val === undefined) return;
    setLoading(true);
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }
    timeout.current = window.setTimeout(async () => {
      await func(val);
      setLoading(false);
    }, timeoutMs);
  }, []);

  return [cb, { loading }];
}
