import {
  ExternalCarrierPayloadFragment,
  OrderDirection,
  ShipmentOrderField,
} from '@/generated/graphql';
import { useDebounced } from '@/hooks/useDebounced';
import { useListCarriers } from '@/hooks/useListCarriers';
import { Routes, route } from '@/utilities/routes';
import { NextRouter, useRouter } from 'next/router';
import { Reducer, useEffect, useMemo, useReducer } from 'react';

import { CarrierCode } from './SortShipments';

type SortShipmentsState = {
  orderByField: ShipmentOrderField;
  orderByDirection: OrderDirection;
  filterShipmentsWithoutLabels: boolean;
  createdAt: string | undefined;
  collectionDate: string | undefined;
  deliveryDate: string | undefined;
  filter: string | undefined;
  carriersFilter: Set<CarrierCode>;
};

type ResetAction = {
  type: 'RESET';
  payload: undefined;
};

// Looks arcane - basically just says that the action type should be a key of the SortShipmentsState type,
// and the payload should be the type in SortShipmentsState that matches that key
type SortShipmentsActions =
  | {
      [K in keyof SortShipmentsState]: {
        type: K;
        payload: SortShipmentsState[K];
      };
    }[keyof SortShipmentsState]
  | ResetAction;

type QueryParamsMappingFunctions = {
  queryParamsToInitialValue: (
    value: string | undefined,
  ) => SortShipmentsActions['payload'];
  valueToQueryParams: (
    value: SortShipmentsActions['payload'],
  ) => string | undefined;
};

const getDefaultFilterValues = (
  carriers: ExternalCarrierPayloadFragment[],
): SortShipmentsState => ({
  orderByField: ShipmentOrderField.CreatedAt,
  orderByDirection: OrderDirection.Desc,
  filterShipmentsWithoutLabels: false,
  createdAt: undefined,
  collectionDate: undefined,
  deliveryDate: undefined,
  filter: undefined,
  carriersFilter: new Set(carriers.map((carrier) => carrier.carrierCode)),
});

const createSortShipmentsReducer: (
  carriers: ExternalCarrierPayloadFragment[],
  router: NextRouter,
) => Reducer<SortShipmentsState, SortShipmentsActions> =
  (carriers, router) => (state, action) => {
    if (action.type === 'RESET') {
      void router.replace(route(router.pathname as Routes, {}), undefined, {
        shallow: true,
      });
      return getDefaultFilterValues(carriers);
    }
    return { ...state, [action.type]: action.payload };
  };

export const useSortShipmentsState = () => {
  const router = useRouter();
  const { carriers, loading: carriersLoading } = useListCarriers();

  useEffect(() => {
    if (carriersLoading) return;

    // When we get the list of carriers that are valid for this merchant, remove anything
    // in the carriersFilter set that aren't valid based on the returned list.
    const validCarriers = Array.from(carriersFilter).filter((carrierFilter) =>
      carriers.some((carrier) => carrierFilter === carrier.carrierCode),
    );

    // This might mean removing all carriers, in which case set the carriersFilter to "all available carriers"
    if (validCarriers.length) {
      setCarriersFilter(new Set(validCarriers));
    } else {
      setCarriersFilter(
        new Set(carriers.map((carrier) => carrier.carrierCode)),
      );
    }
  }, [carriers.length, carriersLoading]);

  const queryParamsMapping: Record<
    keyof SortShipmentsState,
    QueryParamsMappingFunctions
  > = useMemo(
    () => ({
      orderByField: {
        queryParamsToInitialValue: (value) =>
          Object.values(ShipmentOrderField).includes(
            value as ShipmentOrderField,
          )
            ? (value as ShipmentOrderField)
            : ShipmentOrderField.CreatedAt,
        valueToQueryParams: (value) => value?.toString(),
      },
      orderByDirection: {
        queryParamsToInitialValue: (value) =>
          Object.values(OrderDirection).includes(value as OrderDirection)
            ? (value as OrderDirection)
            : OrderDirection.Desc,
        valueToQueryParams: (value) => value?.toString(),
      },
      filterShipmentsWithoutLabels: {
        queryParamsToInitialValue: (value) => !!value,
        valueToQueryParams: (value) => (value ? 'true' : undefined),
      },
      createdAt: {
        queryParamsToInitialValue: (value) => value,
        valueToQueryParams: (value) => value?.toString() || undefined,
      },
      collectionDate: {
        queryParamsToInitialValue: (value) => value,
        valueToQueryParams: (value) => value?.toString() || undefined,
      },
      deliveryDate: {
        queryParamsToInitialValue: (value) => value,
        valueToQueryParams: (value) => value?.toString() || undefined,
      },
      carriersFilter: {
        queryParamsToInitialValue: (value) => {
          const carrierUrlArray =
            typeof value === 'string'
              ? decodeURIComponent(value).split(',')
              : value;

          if (!carrierUrlArray) return new Set<string>();

          return new Set(carrierUrlArray);
        },
        valueToQueryParams: (value) => {
          // This is mostly to narrow the type, and shouldn't actually be thrown
          if (typeof value !== 'object')
            throw new Error(
              'Invalid type passed when syncing query params for carriersFilter',
            );

          if (value.size === (carriers?.length || 0)) return undefined;
          const carrierQueryParam = Array.from(value || [])
            .sort()
            .join(',');
          return encodeURIComponent(carrierQueryParam) || undefined;
        },
      },
      filter: {
        queryParamsToInitialValue: (value) => value,
        valueToQueryParams: (value) => value?.toString() || undefined,
      },
    }),
    [carriers],
  );

  const initialState = Object.keys(getDefaultFilterValues(carriers)).reduce(
    (acc, key) => {
      const queryStringValue = router.query[key];
      const value =
        typeof queryStringValue === 'string' ? queryStringValue : undefined;

      const stateValue =
        queryParamsMapping[key as keyof SortShipmentsState][
          'queryParamsToInitialValue'
        ](value);
      return { ...acc, [key]: stateValue };
    },
    { ...getDefaultFilterValues(carriers) },
  );

  const sortShipmentsReducer = useMemo(
    () => createSortShipmentsReducer(carriers, router),
    [carriers],
  );

  const [state, dispatch] = useReducer(sortShipmentsReducer, initialState);

  const {
    setOrderByField,
    setOrderByDirection,
    setFilterShipmentsWithoutLabels,
    setCarriersFilter,
    setCreatedAt,
    setCollectionDate,
    setDeliveryDate,
    _setFilter,
    resetFilters,
  } = useMemo(() => {
    const syncQueryParamsAndDispatch = (
      action: Exclude<SortShipmentsActions, ResetAction>,
    ) => {
      if (action.type === 'filter') return;

      const newQueryParamsValue = queryParamsMapping[action.type][
        'valueToQueryParams'
      ](action.payload);

      void router.replace(
        route(router.pathname as Routes, {
          ...router.query,
          [action.type]: newQueryParamsValue,
        }),
        undefined,
        { shallow: true },
      );

      dispatch(action);
    };

    const setOrderByField = (payload: ShipmentOrderField) =>
      syncQueryParamsAndDispatch({ type: 'orderByField', payload });
    const setOrderByDirection = (payload: OrderDirection) =>
      syncQueryParamsAndDispatch({ type: 'orderByDirection', payload });
    const setFilterShipmentsWithoutLabels = (payload: boolean) =>
      syncQueryParamsAndDispatch({
        type: 'filterShipmentsWithoutLabels',
        payload,
      });
    const setCarriersFilter = (payload: Set<CarrierCode>) =>
      syncQueryParamsAndDispatch({ type: 'carriersFilter', payload });
    const setCreatedAt = (payload: string | undefined) =>
      syncQueryParamsAndDispatch({ type: 'createdAt', payload });
    const setCollectionDate = (payload: string | undefined) =>
      syncQueryParamsAndDispatch({ type: 'collectionDate', payload });
    const setDeliveryDate = (payload: string | undefined) =>
      syncQueryParamsAndDispatch({ type: 'deliveryDate', payload });
    const _setFilter = (payload: string) =>
      dispatch({ type: 'filter', payload });
    const resetFilters = () => dispatch({ type: 'RESET', payload: undefined });

    return {
      setOrderByField,
      setOrderByDirection,
      setFilterShipmentsWithoutLabels,
      setCarriersFilter,
      setCreatedAt,
      setCollectionDate,
      setDeliveryDate,
      _setFilter,
      resetFilters,
    };
  }, [dispatch, carriers, router, queryParamsMapping]);

  const [setFilter] = useDebounced(
    async (value: string) => _setFilter(value),
    500,
  );
  const {
    orderByField,
    orderByDirection,
    filterShipmentsWithoutLabels,
    carriersFilter,
    createdAt,
    collectionDate,
    deliveryDate,
    filter,
  } = state;

  return {
    orderByField,
    setOrderByField,
    orderByDirection,
    setOrderByDirection,
    filterShipmentsWithoutLabels,
    setFilterShipmentsWithoutLabels,
    carriersFilter,
    setCarriersFilter,
    createdAt,
    setCreatedAt,
    collectionDate,
    setCollectionDate,
    deliveryDate,
    setDeliveryDate,
    filter,
    setFilter,
    resetFilters,
  };
};
