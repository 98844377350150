import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import {
  ListExternalShipmentsInput,
  ListShipmentsInput,
  PackStatus,
  SearchExternalShipmentsInput,
  SearchShipmentsInput,
  useListExternalShipmentsQuery,
  useListShipmentsLazyQuery,
  useListShipmentsQuery,
  useReadShipmentsByTrackingNumberQuery,
  useSearchExternalShipmentsQuery,
  useSearchShipmentsQuery,
} from '@/generated/graphql';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { PACKFLEET_CARRIER, useListCarriers } from '@/hooks/useListCarriers';
import { listQueryToNodes } from '@/utilities/graphql';
import { sortMixedShipments } from '@/utilities/shipments';

import { useSortShipments } from './SortShipments';
import { useCollectionLocations } from './useCollectionLocations';

export const NUM_SHIPMENTS_TO_FETCH_BACKEND = 50;
export const NUM_SHIPMENTS_TO_FETCH_ASYNC = 500;
const NUM_SHIPMENT_TO_FETCH_SEARCH = 50;

export const useShipments = ({
  limit = NUM_SHIPMENTS_TO_FETCH_ASYNC,
  status,
}: {
  limit?: number;
  status?: PackStatus[];
}) => {
  const router = useRouter();
  const {
    orderByField,
    orderByDirection,
    collectionDate,
    deliveryDate,
    createdAt,
    filterShipmentsWithoutLabels,
    filter,
    carriersFilter,
  } = useSortShipments();
  const organization = useCurrentUser()?.organization;
  const { selectedLocation } = useCollectionLocations();
  const { loading: loadingCarriers } = useListCarriers();

  const requestedShipmentsStr = (router.query.shipment ?? '') as string;
  const requestedShipmentsIds = requestedShipmentsStr
    ? requestedShipmentsStr.split(',')
    : [];

  const orderBy =
    orderByField && orderByDirection
      ? {
          field: orderByField,
          direction: orderByDirection,
        }
      : undefined;

  const input: ListShipmentsInput | SearchShipmentsInput = {
    limit,
    status,
    orderBy,
    createdAt,
    collectionDate,
    labelsPrinted: filterShipmentsWithoutLabels ? false : undefined,
    collectionLocationId: selectedLocation?.id,
    deliveryDate,
  };

  const externalShipmentsInput:
    | ListExternalShipmentsInput
    | SearchExternalShipmentsInput = {
    limit,
    orderBy,
    status,
    carriers: Array.from(carriersFilter),
    createdAt,
    collectionDate,
    collectionLocationId: selectedLocation?.id,
    deliveryDate,
  };

  const skipListAllShipments =
    requestedShipmentsIds.length > 0 || !status || !!filter || loadingCarriers;

  const skipListInternalShipments =
    skipListAllShipments || !carriersFilter.has(PACKFLEET_CARRIER.carrierCode);

  const skipListExternalShipments =
    skipListAllShipments ||
    !organization?.externalShipmentManagementEnabled ||
    (carriersFilter.size === 1 &&
      carriersFilter.has(PACKFLEET_CARRIER.carrierCode)) ||
    !carriersFilter.size;

  const {
    data: listShipmentsData,
    loading: listShipmentsLoading,
    fetchMore,
  } = useListShipmentsQuery({
    variables: {
      input,
    },
    skip: skipListInternalShipments,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: listExternalShipmentsData,
    loading: listExternalShipmentsLoading,
    fetchMore: fetchMoreExternalShipments,
  } = useListExternalShipmentsQuery({
    variables: {
      input: externalShipmentsInput,
    },
    skip: skipListExternalShipments,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: searchShipmentsData,
    loading: searchShipmentsLoading,
    fetchMore: searchShipmentsFetchMore,
  } = useSearchShipmentsQuery({
    variables: {
      input: {
        ...input,
        // Search shipments across all statuses
        status: undefined,
        query: filter ?? '',
        limit: NUM_SHIPMENT_TO_FETCH_SEARCH,
      },
    },
    skip: !status || !filter,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: searchExternalShipmentsData,
    loading: searchExternalShipmentsLoading,
    fetchMore: searchExternalShipmentsFetchMore,
  } = useSearchExternalShipmentsQuery({
    variables: {
      input: {
        ...externalShipmentsInput,
        // Search shipments across all statuses
        status: undefined,
        query: filter ?? '',
        limit: NUM_SHIPMENT_TO_FETCH_SEARCH,
      },
    },
    skip:
      !status || !filter || !organization?.externalShipmentManagementEnabled,
    fetchPolicy: 'cache-and-network',
  });

  const { data: readShipmentsData, loading: readShipmentsLoading } =
    useReadShipmentsByTrackingNumberQuery({
      variables: {
        input: {
          trackingNumbers: requestedShipmentsIds,
        },
      },
      skip: requestedShipmentsIds.length === 0,
    });

  const allShipments = useMemo(
    () => [
      ...(listQueryToNodes(listExternalShipmentsData?.listExternalShipments) ??
        []),
      ...(listQueryToNodes(
        searchExternalShipmentsData?.searchExternalShipments,
      ) ?? []),
      ...(listQueryToNodes(listShipmentsData?.listShipments) ?? []),
      ...(listQueryToNodes(searchShipmentsData?.searchShipments) ?? []),
      ...(readShipmentsData?.readShipmentsByTrackingNumber?.shipments ?? []),
    ],
    [
      listShipmentsData,
      readShipmentsData,
      searchShipmentsData,
      listExternalShipmentsData,
      searchExternalShipmentsData,
    ],
  );

  const loading =
    listShipmentsLoading ||
    readShipmentsLoading ||
    searchShipmentsLoading ||
    listExternalShipmentsLoading ||
    searchExternalShipmentsLoading;

  const hasMore =
    listShipmentsData?.listShipments?.pageInfo.hasNextPage ??
    searchShipmentsData?.searchShipments?.pageInfo.hasNextPage ??
    false;

  const fetchMoreShipments = useCallback(async () => {
    if (!hasMore) return;

    if (filter) {
      const edges = searchShipmentsData?.searchShipments?.edges ?? [];
      const externalShipmentsEdges =
        searchExternalShipmentsData?.searchExternalShipments?.edges ?? [];

      await searchShipmentsFetchMore({
        variables: {
          input: {
            ...input,
            query: filter,
            offset: edges.length || undefined,
            limit: NUM_SHIPMENT_TO_FETCH_SEARCH,
          },
        },
      });

      await searchExternalShipmentsFetchMore({
        variables: {
          input: {
            ...externalShipmentsInput,
            query: filter,
            offset: externalShipmentsEdges.length || undefined,
            limit: NUM_SHIPMENT_TO_FETCH_SEARCH,
          },
        },
      });
    } else {
      const shipmentsEdges = listShipmentsData?.listShipments?.edges ?? [];
      const lastShipmentsCursor =
        shipmentsEdges[shipmentsEdges.length - 1]?.cursor;

      const externalShipmentsEdges =
        listExternalShipmentsData?.listExternalShipments?.edges ?? [];
      const lastExternalShipmentsCursor =
        externalShipmentsEdges[externalShipmentsEdges.length - 1]?.cursor;

      await fetchMore({
        variables: {
          input: {
            ...input,
            cursor: orderBy ? undefined : lastShipmentsCursor,
            offset:
              orderBy && shipmentsEdges.length
                ? shipmentsEdges.length
                : undefined,
          },
        },
      });

      await fetchMoreExternalShipments({
        variables: {
          input: {
            ...externalShipmentsInput,
            cursor: orderBy ? undefined : lastExternalShipmentsCursor,
            offset:
              orderBy && externalShipmentsEdges.length
                ? externalShipmentsEdges.length
                : undefined,
          },
        },
      });
    }
  }, [hasMore, listShipmentsData, searchShipmentsData, filter]);

  const [
    fetchShipmentsWithAnyStatus,
    { loading: loadingShipmentsWithAnyStatus },
  ] = useListShipmentsLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ...input,
        limit: 2000,
        status: Object.values(PackStatus),
      },
    },
  });

  const sortedShipments = useMemo(
    () => sortMixedShipments(allShipments, orderBy?.field, orderByDirection),
    [allShipments, orderBy?.field, orderByDirection],
  );

  return {
    shipments: sortedShipments,
    fetchMore: fetchMoreShipments,
    loading,
    hasMore,
    fetchShipmentsWithAnyStatus,
    loadingShipmentsWithAnyStatus,
  };
};
